


































































































































































@import '@design';

.form-greeting {
  // TODO (ESS) : Dont hardcode
  font-family: 'motor', serif !important;
  border-bottom: 2px solid $color-db-purple;
}
